import React, { useEffect } from 'react';

import {
  Box,
  Grid,
  GridItem,
  Heading,
  Hr,
  Main,
  Notification,
  Paragraph,
  Strong,
  Link,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useNavigate } from '@interstellar/react-app-routing';
import {
  logTealiumButtonClickEvent,
  logTealiumPageViewEvent,
} from 'client/tealium';

import { ErrorPageContent } from './ErrorPage.config';

function ErrorPage() {
  const content = useContent<ErrorPageContent>();
  const { title, box, applicationHours } = content;
  const navigate = useNavigate();

  useEffect(() => {
    logTealiumPageViewEvent({
      stepNumber: undefined,
      stepName: 'Error Page',
      action: 'Error',
      actionNarrative: 'Service unavailable - Technical error',
      applicationState: 'Declined',
    });
  }, []);

  return (
    <Main>
      <Heading as="h1" size="s7">
        {title}
      </Heading>

      <Grid>
        <GridItem md={8}>
          <Hr marginTop="none" />

          <Notification
            heading={box.title}
            headingLevel={2}
            sentiment="critical"
          >
            <Paragraph>{box.para1}</Paragraph>
            <Paragraph>
              {box.para2}
              <Strong>{box.para2Strong}</Strong>
            </Paragraph>
            <Paragraph>{box.para3}</Paragraph>
          </Notification>
        </GridItem>

        <GridItem md={4}>
          <Box>
            <Heading size="s4">{applicationHours.title}</Heading>
            <Paragraph>{applicationHours.mainText}</Paragraph>
            <Paragraph>{applicationHours.monToSatHours}</Paragraph>
            <Paragraph marginBottom="none">
              {applicationHours.sunHours}
            </Paragraph>
          </Box>
        </GridItem>
      </Grid>
      <Link
        as="button"
        iconPosition="left"
        data-testid="navigate-back-button"
        onClick={() => {
          navigate(-1);
          logTealiumButtonClickEvent({ label: 'button/back' });
        }}
      >
        {content.backButtonLabel}
      </Link>
    </Main>
  );
}

export default ErrorPage;
