import React from 'react';

import { Box, Heading, Paragraph, Hr } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { FullWidthLink } from 'client/components/fullWidthLink';
import { logTealiumButtonClickEvent } from 'client/tealium';

import { MortgageOverviewPageContent } from '../../MortgageOverviewPage.config';

function RedemptionPanel() {
  const { redemptionPanel } = useContent<MortgageOverviewPageContent>();

  const { title, supportiveText, link } = redemptionPanel;

  return (
    <Box bgColor="neutral2" data-testid="overview-redemption-panel">
      <Heading as="h2" size="s3" marginBottom="02">
        {title}
      </Heading>
      <Paragraph marginBottom="none">{supportiveText}</Paragraph>
      <Hr marginTop="05" marginBottom="05" />
      <FullWidthLink
        to={link.url}
        data-testid="overview-redemption-link"
        onClick={() =>
          logTealiumButtonClickEvent({
            label: 'button/redemption-find-out-the-cost',
          })
        }
      >
        {link.text}
      </FullWidthLink>
    </Box>
  );
}

export default RedemptionPanel;
