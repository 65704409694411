import { logTealiumPageViewEvent } from './logTealiumPageViewEvent';

/**
 * @deprecated This usage has been deprecated and replaced by the logTealiumPageViewEvent function, please use that instead.
 *
 * @param journeyStepName         The name of the journey step, usually the page name.
 * @param journeyStep             The step number to use for this page. e.g. "1", "2" Passing undefined will inherit the previous step number. Can also provide an offset from the previous step number using - and + prefixes.
 * @param journeyActionNarrative  The narrative of the action performed, e.g. "Error"
 * @param applicationState        The current state of the application e.g. "Pre-Application", "Application", "Completed", "Fulfilled", "Declined", "Referred"
 */
export function logTealiumNavEvent(
  journeyStepName: string,
  journeyStep: string | undefined,
  journeyActionNarrative: string | undefined,
  applicationState: string | undefined,
) {
  logTealiumPageViewEvent({
    stepNumber: journeyStep,
    stepName: journeyStepName,
    actionNarrative: journeyActionNarrative,
    applicationState,
  });
}
