import React, { useState } from 'react';

import {
  Box,
  Heading,
  IconAdditionalBorrowing,
  Text,
  Dialog,
  Paragraph,
  Link,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { LinkIconCard } from 'client/components/linkIconCard';
import { PercentageIndicator } from 'client/components/percentageIndicator';
import { BorrowMore } from 'client/routes/manifest';
import { Overview } from 'client/services/types/overview';
import { useAppSelector } from 'client/store';
import { logTealiumButtonClickEvent } from 'client/tealium';

import {
  LeftContainer,
  RightContainer,
  SideBySideContainer,
} from './LoanToValuePanel.styled';
import { MortgageOverviewPageContent } from '../../MortgageOverviewPage.config';
import { formatCurrency } from '../../utils/formatCurrency';

function LoanToValuePanel() {
  const { loanToValuePanel } = useContent<MortgageOverviewPageContent>();
  const state = useAppSelector<Overview>((appState) => appState.overview);

  const [isLTVDialogOpen, setIsLTVDialogOpen] = useState(false);
  const { overview } = state || {};

  const {
    totalMortgageBalance,
    estimatedEquityBasedOnIndexValuation,
    loanToValueRatio,
    eligibleToBorrowMore,
  } = overview || {
    totalMortgageBalance: 0,
    estimatedEquityBasedOnIndexValuation: 0,
    loanToValueRatio: 0,
  };

  return (
    <>
      {isLTVDialogOpen && (
        <Dialog
          open={isLTVDialogOpen}
          title={loanToValuePanel.whatIsLTVModal.heading}
          onClose={() => {
            setIsLTVDialogOpen(false);
            logTealiumButtonClickEvent({
              label: 'button/close-what-is-ltv-modal',
            });
          }}
        >
          <Paragraph marginBottom="none">
            {loanToValuePanel.whatIsLTVModal.supportiveText}
          </Paragraph>
        </Dialog>
      )}
      <Box bgColor="neutral2" data-testid="overview-loan-to-value-panel">
        <Heading as="h2">{loanToValuePanel.heading}</Heading>
        <PercentageIndicator
          markerPercent="80"
          percent={loanToValueRatio.toFixed(2)}
          data-testid="overview-ltv-slider"
        />
        <SideBySideContainer>
          <LeftContainer>
            <Text>{loanToValuePanel.equityLabel}</Text>
            <Text
              size="s4"
              weight="bold"
              as="p"
              data-testid="overview-estimated-equity"
            >
              {formatCurrency(estimatedEquityBasedOnIndexValuation, 0)}
            </Text>
          </LeftContainer>
          <RightContainer>
            <Text as="p" marginBottom="01">
              {loanToValuePanel.balanceLabel}
            </Text>
            <Text
              size="s4"
              weight="bold"
              as="p"
              marginBottom="03"
              data-testid="overview-loan-balance"
            >
              {formatCurrency(totalMortgageBalance)}
            </Text>
          </RightContainer>
        </SideBySideContainer>

        <Link
          as="button"
          onClick={() => {
            setIsLTVDialogOpen(true);
            logTealiumButtonClickEvent({
              label: 'button/open-what-is-ltv-modal',
            });
          }}
          data-testid="ltv-modal-link"
        >
          {loanToValuePanel.whatIsLTVModal.label}
        </Link>

        {eligibleToBorrowMore && (
          <LinkIconCard
            to={BorrowMore}
            icon={<IconAdditionalBorrowing inverse />}
            actionText={loanToValuePanel.borrowMoreCTA.heading}
            supportingText={loanToValuePanel.borrowMoreCTA.supportiveText}
            data-testid="overview-borrow-more-link"
            onClick={() => {
              logTealiumButtonClickEvent({ label: 'button/borrowmore' });
            }}
          />
        )}
      </Box>
    </>
  );
}

export default LoanToValuePanel;
