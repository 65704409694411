import React, { useState } from 'react';

import { Accordion, Heading, Paragraph } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import DottedHr from 'client/components/dottedHr/DottedHr';
import { OnceMadePaymentAccordionContent } from 'client/content/OnceMadePaymentAccordion.config';
import { logTealiumAccordionEvent } from 'client/tealium';

function OnceMadePaymentAccordion() {
  const content = useContent<OnceMadePaymentAccordionContent>();
  const [open, setOpen] = useState(false);

  const {
    label,
    confirmationOfRepaymentSection,
    yourPaymentsSection,
    insuranceSection,
    legalChargesEnglandWalesIrelandSection,
    legalChargesScotlandSection,
    registeredWithLandRegistrySection,
    notRegisteredWithLandRegistrySection,
  } = content;

  return (
    <Accordion
      label={label}
      variation="inpage1"
      data-testid="once-made-payment-accordion"
      open={open}
      onClick={() => {
        if (!open) {
          logTealiumAccordionEvent({ label: 'once-made-payment' });
        }
        setOpen((current) => !current);
      }}
    >
      <Heading
        as="h3"
        size="s3"
        marginBottom="03"
        data-testid="confirmation-of-repayment-heading"
      >
        {confirmationOfRepaymentSection.heading}
      </Heading>
      <Paragraph data-testid="confirmation-of-repayment-paragraph">
        {confirmationOfRepaymentSection.paragraph1}
      </Paragraph>

      <DottedHr marginTop="05" marginBottom="05" />

      <Heading
        as="h3"
        size="s3"
        marginBottom="03"
        data-testid="your-payments-heading"
      >
        {yourPaymentsSection.heading}
      </Heading>
      <Paragraph data-testid="your-payments-paragraph-1">
        {yourPaymentsSection.paragraph1}
      </Paragraph>
      <Paragraph data-testid="your-payments-paragraph-2">
        {yourPaymentsSection.paragraph2}
      </Paragraph>
      <Paragraph marginBottom="none" data-testid="your-payments-paragraph-3">
        {yourPaymentsSection.paragraph3}
      </Paragraph>

      <DottedHr marginTop="05" marginBottom="05" />

      <Heading
        as="h3"
        size="s3"
        marginBottom="03"
        data-testid="insurance-heading"
      >
        {insuranceSection.heading}
      </Heading>
      <Paragraph marginBottom="none" data-testid="insurance-paragraph-1">
        {insuranceSection.paragraph1}
      </Paragraph>

      <DottedHr marginTop="05" marginBottom="05" />

      <Heading
        as="h3"
        size="s3"
        marginBottom="03"
        data-testid="legal-charges-heading"
      >
        {legalChargesEnglandWalesIrelandSection.heading}
      </Heading>
      <Paragraph marginBottom="none" data-testid="legal-charges-paragraph">
        {legalChargesEnglandWalesIrelandSection.paragraph1}
      </Paragraph>

      <DottedHr marginTop="05" marginBottom="05" />

      <Heading
        as="h3"
        size="s3"
        marginBottom="03"
        data-testid="legal-charges-scotland-heading"
      >
        {legalChargesScotlandSection.heading}
      </Heading>
      <Paragraph data-testid="legal-charges-scotland-paragraph-1">
        {legalChargesScotlandSection.paragraph1}
      </Paragraph>
      <Paragraph
        marginBottom="none"
        data-testid="legal-charges-scotland-paragraph-2"
      >
        {legalChargesScotlandSection.paragraph2}
      </Paragraph>

      <DottedHr marginTop="05" marginBottom="05" />

      <Heading
        as="h3"
        size="s3"
        marginBottom="03"
        data-testid="land-registry-heading"
      >
        {registeredWithLandRegistrySection.heading}
      </Heading>
      <Paragraph marginBottom="none" data-testid="land-registry-paragraph-1">
        {registeredWithLandRegistrySection.paragraph1}
      </Paragraph>

      <DottedHr marginTop="05" marginBottom="05" />

      <Heading
        as="h3"
        size="s3"
        marginBottom="03"
        data-testid="not-land-registry-heading"
      >
        {notRegisteredWithLandRegistrySection.heading}
      </Heading>
      <Paragraph data-testid="not-land-registry-paragraph-1">
        {notRegisteredWithLandRegistrySection.paragraph1}
      </Paragraph>
    </Accordion>
  );
}

export default OnceMadePaymentAccordion;
