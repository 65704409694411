import React, { useEffect, useMemo, useState } from 'react';

import {
  Main,
  Heading,
  Paragraph,
  IconLightBulb,
  Link,
  Dialog,
  Strong,
  Spinner,
  Box,
  Grid,
  GridItem,
  Hr,
  Button,
  ContentGroup,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useNavigate } from '@interstellar/react-app-routing';
import { StyledButtonPattern } from 'client/components/styledButtonPattern';
import {
  logTealiumButtonClickEvent,
  logTealiumPageViewEvent,
} from 'client/tealium';

import MultipleSubAccountAccordion from './components/multipleSubAccountAccordion/MultipleSubAccountAccordion';
import SingleSubAccountPanel from './components/singleSubAccountPanel/SingleSubAccountPanel';
import useERC from './hooks/useERC';
import { StyledContainer } from './OverpaymentsAllowance.styled';
import { OverpaymentsAllowanceContent } from './OverpaymentsAllowancePage.config';
import * as routes from '../manifest';

function OverpaymentAllowancePage(): React.JSX.Element {
  const {
    heading,
    summaryText,
    backButtonLabel,
    findOutHowToPayButton,
    returnToMortgageOverviewLink,
    infoBox,
    subaccountsInfo,
    subaccountsDialog,
    howWeWorkOutAllowanceDialog,
  } = useContent<OverpaymentsAllowanceContent>();

  const navigate = useNavigate();

  const [showHowWeWorkOutAllowance, setShowHowWeWorkOutAllowance] =
    useState(false);
  const [showSubaccountDialog, setShowSubaccountDialog] = useState(false);

  const redemptionDate = useMemo(() => {
    const today = new Date();
    return {
      year: today.getFullYear().toString(),
      month: (today.getMonth() + 1).toString(),
      day: today.getDate().toString(),
    };
  }, []);

  const { requestERCData, isLoading, ercConcessionAmountDetails, subAccounts } =
    useERC();

  useEffect(() => {
    requestERCData(redemptionDate);
  }, [requestERCData, redemptionDate]);

  useEffect(() => {
    logTealiumPageViewEvent({
      stepNumber: '1',
      stepName: 'Overpayment allowance page',
      applicationState: 'Pre-Application',
    });
  }, []);

  if (isLoading) {
    return (
      <Main>
        <Spinner />
      </Main>
    );
  }

  return (
    <Main>
      <StyledContainer>
        <Link
          as="button"
          onClick={() => {
            logTealiumButtonClickEvent({ label: 'button/back' });
            navigate(routes.MortgageOverview);
          }}
          iconPosition="left"
          data-testid="back-button-link"
        >
          {backButtonLabel}
        </Link>
      </StyledContainer>
      <Hr marginTop="none" />

      <Grid>
        <GridItem xs={12} lg={8}>
          {showHowWeWorkOutAllowance && (
            <Dialog
              data-testid="how-we-work-out-allowance-dialog"
              open={showHowWeWorkOutAllowance}
              onClose={() => {
                logTealiumButtonClickEvent({
                  label: 'button/close-how-we-work-out-allowance-dialog',
                });
                setShowHowWeWorkOutAllowance(false);
              }}
            >
              <Strong>{howWeWorkOutAllowanceDialog.fixedRateHeading}</Strong>
              <Paragraph marginTop="04">
                {howWeWorkOutAllowanceDialog.fixedRateText1}
              </Paragraph>
              <Paragraph>
                {howWeWorkOutAllowanceDialog.fixedRateText2}
              </Paragraph>
              <Paragraph>
                {howWeWorkOutAllowanceDialog.fixedRateText3}
              </Paragraph>

              <Strong>{howWeWorkOutAllowanceDialog.variableRateHeading}</Strong>
              <Paragraph marginTop="04">
                {howWeWorkOutAllowanceDialog.variableRateText1}
              </Paragraph>
            </Dialog>
          )}

          {showSubaccountDialog && (
            <Dialog
              data-testid="sub-account-info-dialog"
              open={showSubaccountDialog}
              onClose={() => {
                logTealiumButtonClickEvent({
                  label: 'button/close-subaccount-info-dialog',
                });
                setShowSubaccountDialog(false);
              }}
            >
              <Paragraph>{subaccountsDialog.text1}</Paragraph>
              <Paragraph>{subaccountsDialog.text2}</Paragraph>
              <Paragraph>{subaccountsDialog.text3}</Paragraph>
              <Paragraph>{subaccountsDialog.text4}</Paragraph>
            </Dialog>
          )}

          <Heading size="s5" as="h1">
            {heading}
          </Heading>
          <Paragraph>{summaryText}</Paragraph>

          <Box>
            <IconLightBulb iconSize="lg" trim color="brand" />
            <Paragraph
              data-testid="overpayment-description-paragraph"
              marginTop="04"
              marginBottom="04"
            >
              {infoBox.overpaymentDescription}
            </Paragraph>

            <Link
              as="button"
              data-testid="how-we-work-out-allowance-link"
              onClick={() => {
                logTealiumButtonClickEvent({
                  label: 'button/open-how-we-work-out-allowance-dialog',
                });
                setShowHowWeWorkOutAllowance(true);
              }}
            >
              {infoBox.howWeWorkOutLinkLabel}
            </Link>
          </Box>

          {ercConcessionAmountDetails?.length > 1 && (
            <>
              <Heading data-testid="subaccount-info-heading" size="s3">
                {subaccountsInfo.heading} {ercConcessionAmountDetails?.length}{' '}
                {subaccountsInfo.heading2}
              </Heading>
              <Paragraph data-testid="subaccount-info-paragraph" marginTop="03">
                {subaccountsInfo.text}
              </Paragraph>
              <Link
                data-testid="subaccount-info-dialog-link"
                as="button"
                onClick={() => {
                  logTealiumButtonClickEvent({
                    label: 'button/open-subaccount-info-dialog',
                  });
                  setShowSubaccountDialog(true);
                }}
              >
                {subaccountsInfo.dialogLink}
              </Link>
            </>
          )}

          <ContentGroup marginTop="03" marginBottom="none">
            {subAccounts && subAccounts.length === 1 && (
              <SingleSubAccountPanel subAccount={subAccounts[0]} />
            )}
            {subAccounts && subAccounts.length > 1 && (
              <>
                {subAccounts.map((subAccount) => (
                  <MultipleSubAccountAccordion
                    key={`sub-acccount-accordion-${subAccount.subAccountNumber}`}
                    subAccount={subAccount}
                  />
                ))}
              </>
            )}

            <StyledButtonPattern>
              <Button
                iconPosition="right"
                onClick={() => {
                  logTealiumButtonClickEvent({
                    label: 'button/find-out-how-to-pay',
                  });
                  navigate(routes.Overpayments);
                }}
              >
                {findOutHowToPayButton}
              </Button>

              <Link
                as="button"
                weight="bold"
                onClick={() => {
                  logTealiumButtonClickEvent({
                    label: 'button/return-to-mortgage-overview',
                  });
                  navigate(routes.MortgageOverview);
                }}
                href={routes.MortgageOverview}
              >
                {returnToMortgageOverviewLink}
              </Link>
            </StyledButtonPattern>
          </ContentGroup>
        </GridItem>
      </Grid>
    </Main>
  );
}

export default OverpaymentAllowancePage;
