import React, { useMemo, useState } from 'react';

import {
  Box,
  Hr,
  Accordion,
  Text,
  Link,
  Dialog,
  Paragraph,
  Container,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import DottedHr from 'client/components/dottedHr/DottedHr';
import { Overview, SubAccount } from 'client/services/types/overview';
import { useAppSelector } from 'client/store';
import { logTealiumButtonClickEvent } from 'client/tealium';

import { MortgageOverviewPageContent } from '../../MortgageOverviewPage.config';
import { formatCurrency } from '../../utils/formatCurrency';
import formatDate from '../../utils/formatDate';

function MortgageInfoPanel() {
  const { mortgageInfoPanel } = useContent<MortgageOverviewPageContent>();
  const state = useAppSelector<Overview>((appState) => appState.overview);

  const [isSubaccountModalOpen, setIsSubaccountModalOpen] = useState(false);

  const { overview } = state || {};

  const {
    mortgageAccountNumber,
    remainingMortgageTermYears,
    remainingMortgageTermMonths,
    totalMonthlyPayment,
    dateOfNextDirectDebitCollection,
    subAccounts,
  } = overview || {
    mortgageAccountNumber: '0',
    remainingMortgageTermYears: 0,
    remainingMortgageTermMonths: 0,
    totalMonthlyPayment: 0,
    dateOfNextDirectDebitCollection: '2000-01-01',
    subAccounts: [],
  };

  const mappedSubAccounts = useMemo(
    () =>
      subAccounts.map(
        (
          {
            interestRate,
            currentBalance,
            productEndDate,
            subAccountNumber,
          }: SubAccount,
          idx: number,
        ) => (
          <div
            key={subAccountNumber}
            data-testid={`overview-sub-account-${subAccountNumber}`}
          >
            <Text size="s3" marginBottom="none">
              Sub-account {subAccountNumber.padStart(2, '0')}:
            </Text>
            <Text as="p">
              Current interest rate:{' '}
              <Text
                weight="bold"
                data-testid={`overview-sub-account-${subAccountNumber}-interest-rate`}
              >
                {interestRate}%
              </Text>{' '}
              {productEndDate && (
                <>
                  {` until `}
                  <Text
                    weight="bold"
                    data-testid={`overview-sub-account-${subAccountNumber}-end-date`}
                  >
                    {formatDate(productEndDate)}
                  </Text>
                </>
              )}
            </Text>
            <Text marginBottom="03">Balance: </Text>
            <Text weight="bold">{formatCurrency(currentBalance)}</Text>
            {idx < subAccounts.length - 1 && (
              <DottedHr marginBottom="03" marginTop="03" />
            )}
          </div>
        ),
      ),
    [subAccounts],
  );

  return (
    <>
      {isSubaccountModalOpen && (
        <Dialog
          open={isSubaccountModalOpen}
          title={mortgageInfoPanel.subAccountsModal.heading}
          onClose={() => {
            setIsSubaccountModalOpen(false);
            logTealiumButtonClickEvent({
              label: 'button/close-subaccount-modal',
            });
          }}
        >
          <Paragraph marginBottom="none">
            {mortgageInfoPanel.subAccountsModal.supportiveText}
          </Paragraph>
        </Dialog>
      )}
      <Box bgColor="neutral2" data-testid="overview-mortgage-info-panel">
        <Text as="p" marginBottom="01">
          {mortgageInfoPanel.accountNumberLabel}
        </Text>
        <Text
          as="p"
          weight="bold"
          data-testid="overview-mortgage-account-number"
        >
          {mortgageAccountNumber}
        </Text>
        <Hr marginTop="04" marginBottom="04" />
        <Text as="p" marginBottom="01">
          {mortgageInfoPanel.mortgageTermLabel}
        </Text>
        <Text
          as="p"
          weight="bold"
          data-testid="overview-mortgage-remaining-term"
        >
          {remainingMortgageTermYears} years {remainingMortgageTermMonths}{' '}
          months
        </Text>
        <Accordion
          label={
            <>
              <Text as="p" marginBottom="01">
                {mortgageInfoPanel.interestRatesLabel}
              </Text>
              <Text as="p" weight="bold">
                {subAccounts.length}
                {mortgageInfoPanel.subAccountsLabel}
              </Text>
            </>
          }
          variation="inpage1"
          marginTop="04"
          data-testid="overview-sub-account-accordion"
        >
          <Container marginBottom="03" padding="none">
            <Link
              as="button"
              onClick={() => {
                setIsSubaccountModalOpen(true);
                logTealiumButtonClickEvent({
                  label: 'button/open-subaccount-modal',
                });
              }}
              data-testid="subaccount-modal-link"
            >
              {mortgageInfoPanel.subAccountsModal.label}
            </Link>
          </Container>

          {mappedSubAccounts}
        </Accordion>
        <Hr marginTop="none" marginBottom="04" />
        <Text as="p" marginBottom="01">
          {mortgageInfoPanel.monthlyPaymentLabel}
        </Text>
        <Text as="p" weight="bold" data-testid="overview-monthly-payment">
          {formatCurrency(totalMonthlyPayment)}
        </Text>
        <Hr marginTop="04" marginBottom="04" />
        <Text as="p" marginBottom="01">
          {mortgageInfoPanel.nextPaymentLabel}
        </Text>
        <Text as="p" weight="bold" data-testid="overview-next-payment">
          {formatDate(dateOfNextDirectDebitCollection)}
        </Text>
      </Box>
    </>
  );
}

export default MortgageInfoPanel;
