import React from 'react';

import { Box, Paragraph, Hr, Heading } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { FullWidthLink } from 'client/components/fullWidthLink';
import * as routes from 'client/routes/manifest';
import { logTealiumButtonClickEvent } from 'client/tealium';

import { MortgageOverviewPageContent } from '../../MortgageOverviewPage.config';

function RentalYieldPanel() {
  const { rentalYieldPanel } = useContent<MortgageOverviewPageContent>();

  const { title, supportiveText, linkLabel } = rentalYieldPanel;

  return (
    <Box bgColor="neutral2" data-testid="rental-yield-panel">
      <Heading as="h2" size="s3" marginBottom="02">
        {title}
      </Heading>
      <Paragraph>{supportiveText}</Paragraph>
      <Hr marginTop="05" marginBottom="05" />
      <FullWidthLink
        to={routes.RentalYield}
        data-testid="overview-rental-yield-link"
        onClick={() =>
          logTealiumButtonClickEvent({ label: 'button/rental-yield-calculate' })
        }
      >
        {linkLabel}
      </FullWidthLink>
    </Box>
  );
}

export default RentalYieldPanel;
