import styled from 'styled-components';

export const StyledIconContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing_04};
`;

export const StyledStampIcon = styled.div`
  border: 1px dashed ${({ theme }) => theme.color_text_subdued};
  border-radius: 50%;
  box-sizing: border-box;
  display: inline-block;
  width: 44px;
  height: 44px;
  text-align: center;
  padding: ${({ theme }) => theme.spacing_02} 0;
  margin: ${({ theme }) => theme.spacing_01};

  /* Set the text span to be 'top' to match default styling from the icon. */
  span {
    vertical-align: top;
  }

  &.active {
    background-color: ${({ theme }) => theme.color_background_panel_brand_1};
    border: 1px solid ${({ theme }) => theme.color_border_brand_1};
  }
`;
