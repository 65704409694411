import React, { useEffect } from 'react';

import {
  Box,
  Grid,
  GridItem,
  Heading,
  Hr,
  Main,
  Notification,
  Paragraph,
  Strong,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { Navigate, useLocation } from '@interstellar/react-app-routing';
import { useServiceAvailabilityQuery } from 'client/services/api';
import { logTealiumPageViewEvent } from 'client/tealium';

import { ServiceUnavailablePageContent } from './ServiceUnavailablePage.config';
import * as routes from '../manifest';

function ServiceUnavailablePage() {
  const { title, box, applicationHours } =
    useContent<ServiceUnavailablePageContent>();
  const { data, isError } = useServiceAvailabilityQuery();
  const location = useLocation();
  const serviceAvailable = data?.serviceAvailable;

  useEffect(() => {
    logTealiumPageViewEvent({
      stepNumber: undefined,
      stepName: 'Service Unavailable Page',
      action: 'Error',
      actionNarrative: 'Service Unavailable',
      applicationState: 'Declined',
    });
  }, []);

  if (serviceAvailable) {
    return <Navigate to={routes.SignIn} />;
  }
  if (isError) {
    return (
      <Navigate to={routes.Error} state={{ previousPath: location.pathname }} />
    );
  }

  return (
    <Main>
      <Heading as="h1" size="s7" data-testid="service-availability-header">
        {title}
      </Heading>

      <Grid>
        <GridItem md={8}>
          <Hr marginTop="none" />

          <Notification
            heading={box.title}
            headingLevel={2}
            sentiment="critical"
            data-testid="service-availability-title"
          >
            <Paragraph>{box.para1}</Paragraph>
            <Paragraph>
              {box.para2}
              <Strong>{box.para2Strong}</Strong>
            </Paragraph>
            <Paragraph>{box.para3}</Paragraph>
          </Notification>
        </GridItem>

        <GridItem md={4}>
          <Box>
            <Heading size="s4">{applicationHours.title}</Heading>
            <Paragraph>{applicationHours.mainText}</Paragraph>
            <Paragraph>{applicationHours.monToSatHours}</Paragraph>
            <Paragraph marginBottom="none">
              {applicationHours.sunHours}
            </Paragraph>
          </Box>
        </GridItem>
      </Grid>
    </Main>
  );
}

export default ServiceUnavailablePage;
