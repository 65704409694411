import React, { useEffect, useState } from 'react';

import {
  Button,
  ContentGroup,
  Grid,
  GridItem,
  Heading,
  Hr,
  IconChevronLeft,
  IconPencil,
  Link,
  Main,
  Paragraph,
  Spinner,
  Text,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useNavigate } from '@interstellar/react-app-routing';
import DottedHr from 'client/components/dottedHr/DottedHr';
import { StyledButtonPattern } from 'client/components/styledButtonPattern';
import * as routes from 'client/routes/manifest';
import {
  logTealiumButtonClickEvent,
  logTealiumPageViewEvent,
} from 'client/tealium';

import DateSelectDialog from './components/DateSelectDialog/DateSelectDialog';
import ErcDialog from './components/ErcDialog/ErcDialog';
import ErcSubAccountAccordion from './components/ErcSubAccountAccordion/ErcSubAccountAccordion';
import NextStepsSection from './components/NexStepsSection/NextStepsSection';
import OnceMadePaymentAccordion from './components/OnceMadePaymentAccordion/OnceMadePaymentAccordion';
import RedemptionBreakdownPanel from './components/RedemptionBreakdownPanel/RedemptionBreakdownPanel';
import TotalCostDialog from './components/TotalCostDialog/TotalCostDialog';
import useRedemption from './hooks/useRedemption';
import { RedemptionContent } from './RedemptionPage.config';
import { StyledContainer } from './RedemptionPage.styled';
import { formatCurrency } from './utils/formatCurrency';
import formatRedemptionDateObject from './utils/formatRedemptionDateObject';

interface FormValues {
  redemptionDate: { day: string; month: string; year: string };
}

function RedemptionPage() {
  const {
    backButtonLabel,
    heading,
    redemptionIntro,
    changeLinkLabel,
    totalCostLinkLabel,
    ercBreakdownSectionHeading,
    ercBreakdownLinkLabel,
    howToPayButtonLabel: payCtaLabel,
    returnLinkLabel: returnToOverviewCtaLabel,
  } = useContent<RedemptionContent>();
  const navigate = useNavigate();

  const [showCalendarDialog, setShowCalendarDialog] = useState(false);
  const [showTotalCostDialog, setShowTotalCostDialog] = useState(false);
  const [showErcDialog, setShowErcDialog] = useState(false);
  const [redemptionDate, setRedemptionDate] = useState(() => {
    const today = new Date();

    const year = today.getFullYear().toString();
    const month = (today.getMonth() + 1).toString();
    const day = today.getDate().toString();

    return { day, month, year };
  });

  const {
    requestPaperStatement,
    redemptionAmount,
    balanceAmount,
    closingAdminCharge,
    mortgageERCAmount,
    subAccounts,
    isLoading,
  } = useRedemption(redemptionDate);

  useEffect(() => {
    logTealiumPageViewEvent({
      stepNumber: '1',
      stepName: 'Redemption results page',
      applicationState: 'Application',
    });
  }, []);

  if (isLoading) {
    return (
      <Main>
        <Spinner />
      </Main>
    );
  }

  return (
    <Main>
      <DateSelectDialog
        open={showCalendarDialog}
        onClose={() => {
          logTealiumButtonClickEvent({
            label: 'button/change-date-dialog-close',
          });
          setShowCalendarDialog(false);
        }}
        redemptionDate={redemptionDate}
        onSubmit={(values: FormValues) => {
          const {
            redemptionDate: { day, month, year },
          } = values;

          // Only set if the date has changed - otherwise we'll retrigger an API request
          if (
            day !== redemptionDate.day ||
            month !== redemptionDate.month ||
            year !== redemptionDate.year
          ) {
            setRedemptionDate({ ...values.redemptionDate });
          }

          setShowCalendarDialog(false);
        }}
      />

      <TotalCostDialog
        open={showTotalCostDialog}
        onClose={() => {
          logTealiumButtonClickEvent({
            label: 'button/total-cost-dialog-close',
          });
          setShowTotalCostDialog(false);
        }}
        redemptionDate={redemptionDate}
      />

      <ErcDialog
        open={showErcDialog}
        onClose={() => {
          logTealiumButtonClickEvent({ label: 'button/erc-dialog-close' });
          setShowErcDialog(false);
        }}
      />

      <StyledContainer>
        <Link
          icon={<IconChevronLeft trim />}
          iconPosition="left"
          as="button"
          data-testid="back-button-link"
          onClick={() => {
            logTealiumButtonClickEvent({ label: 'button/back' });
            navigate(routes.MortgageOverview);
          }}
        >
          {backButtonLabel}
        </Link>
      </StyledContainer>

      <Hr marginTop="none" />

      <Grid>
        <GridItem xs={12} lg={8}>
          <Heading as="h1" size="s5" data-testid="page-heading">
            {heading}
          </Heading>

          <Paragraph marginBottom="01">{redemptionIntro}</Paragraph>
          <Paragraph>
            <Text marginRight="03" weight="bold" data-testid="redemption-date">
              {formatRedemptionDateObject(redemptionDate)}
            </Text>
            <Link
              as="button"
              icon={<IconPencil trim />}
              iconPosition="left"
              onClick={() => {
                logTealiumButtonClickEvent({ label: 'button/change-date' });
                setShowCalendarDialog(true);
              }}
              data-testid="change-date-link"
            >
              {changeLinkLabel}
            </Link>
          </Paragraph>

          <Paragraph>
            <Heading as="span" size="s6" data-testid="redemption-amount">
              {formatCurrency(redemptionAmount)}
            </Heading>
          </Paragraph>

          <Paragraph>
            <Link
              as="button"
              onClick={() => {
                logTealiumButtonClickEvent({
                  label: 'button/info-about-the-total-cost',
                });
                setShowTotalCostDialog(true);
              }}
              data-testid="total-cost-dialog-link"
            >
              {totalCostLinkLabel}
            </Link>
          </Paragraph>

          <RedemptionBreakdownPanel
            redemptionDate={redemptionDate}
            balanceAmount={balanceAmount}
            closingAdminCharge={closingAdminCharge}
            mortgageERCAmount={mortgageERCAmount}
          />

          <DottedHr />

          <Heading
            size="s4"
            marginBottom="03"
            data-testid="erc-breakdown-heading"
          >
            {ercBreakdownSectionHeading}
          </Heading>

          <Link
            as="button"
            onClick={() => {
              logTealiumButtonClickEvent({ label: 'button/info-about-ercs' });
              setShowErcDialog(true);
            }}
            data-testid="erc-info-dialog-link"
          >
            {ercBreakdownLinkLabel}
          </Link>

          <ContentGroup marginBottom="05" marginTop="05">
            {subAccounts.map((subAccount) => (
              <ErcSubAccountAccordion
                redemptionDate={redemptionDate}
                subAccount={subAccount}
                key={`sub-account-${subAccount.subAccountNumber}`}
              />
            ))}
          </ContentGroup>

          <NextStepsSection
            onRequestPaperStatementClick={() => {
              logTealiumButtonClickEvent({
                label: 'button/request-a-paper-statement',
              });
              requestPaperStatement(redemptionDate);
            }}
          />
          <OnceMadePaymentAccordion />

          <StyledButtonPattern>
            <Button
              iconPosition="right"
              variation="primary"
              onClick={() => {
                logTealiumButtonClickEvent({
                  label: 'button/find-out-how-to-pay',
                });
                navigate(routes.MakePayment);
              }}
              data-testid="how-to-pay-button"
            >
              {payCtaLabel}
            </Button>
            <Link
              weight="bold"
              as="button"
              onClick={() => {
                logTealiumButtonClickEvent({
                  label: 'button/return-to-mortgage-overview',
                });
                navigate(routes.MortgageOverview);
              }}
              data-testid="return-to-overview-link"
            >
              {returnToOverviewCtaLabel}
            </Link>
          </StyledButtonPattern>
        </GridItem>
      </Grid>
    </Main>
  );
}

export default RedemptionPage;
