import React, { useEffect } from 'react';

import {
  Button,
  ButtonPattern,
  Grid,
  GridItem,
  Link,
  Main,
  Notification,
  Paragraph,
  Text,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useNavigate } from '@interstellar/react-app-routing';
import * as routes from 'client/routes/manifest';
import {
  logTealiumButtonClickEvent,
  logTealiumPageViewEvent,
} from 'client/tealium';

import { RedemptionUnavailableContent } from './RedemptionUnavailablePage.config';

function RedemptionUnavailablePage() {
  const content = useContent<RedemptionUnavailableContent>();
  const navigate = useNavigate();

  useEffect(() => {
    logTealiumPageViewEvent({
      stepNumber: '2',
      stepName: 'Redemption - Unable to show figures',
      action: 'Error',
      actionNarrative: 'Unable to show figures',
      applicationState: 'Declined',
    });
  }, []);

  const { heading, para1a, telLinkNumber, para1b, para2, returnLinkLabel } =
    content;

  return (
    <Main>
      <Grid>
        <GridItem xs={12} lg={8}>
          <Notification
            sentiment="warning"
            heading={heading}
            data-testid="redemption-unavailable-notification"
          >
            <Paragraph data-testid="redemption-unavailable-notification-paragraph-1">
              <Text>{para1a}</Text>
              <Link
                href={`tel:${telLinkNumber.replace(/\s/g, '')}`}
                data-testid="help-telephone-link"
                onClick={() => {
                  logTealiumButtonClickEvent({ label: 'button/helpline' });
                }}
              >
                {telLinkNumber}
              </Link>
              <Text>{para1b}</Text>
            </Paragraph>
            <Paragraph
              marginBottom="none"
              data-testid="redemption-unavailable-notification-paragraph-2"
            >
              {para2}
            </Paragraph>
          </Notification>
          <ButtonPattern>
            <Button
              iconPosition="left"
              onClick={() => {
                logTealiumButtonClickEvent({
                  label: 'button/return-to-mortgage-overview',
                });
                navigate(routes.MortgageOverview);
              }}
              data-testid="return-to-overview-button"
            >
              {returnLinkLabel}
            </Button>
          </ButtonPattern>
        </GridItem>
      </Grid>
    </Main>
  );
}

export default RedemptionUnavailablePage;
