import React from 'react';

import { Box, Heading, Hr } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { FullWidthLink } from 'client/components/fullWidthLink';
import { logTealiumButtonClickEvent } from 'client/tealium';

import { MortgageOverviewPageContent } from '../../MortgageOverviewPage.config';

function RequestsPanel() {
  const { requestsPanel } = useContent<MortgageOverviewPageContent>();

  return (
    <Box bgColor="neutral2" data-testid="overview-requests-panel">
      <Heading size="s3" as="h2">
        {requestsPanel.heading}
      </Heading>
      <FullWidthLink
        to={requestsPanel.certificateOfInterestLink.url}
        data-testid="overview-certificate-of-interest-link"
        onClick={() =>
          logTealiumButtonClickEvent({
            label: 'button/certificate-mortgage-interest',
          })
        }
      >
        {requestsPanel.certificateOfInterestLink.text}
      </FullWidthLink>
      <Hr marginTop="04" marginBottom="04" />
      <FullWidthLink
        to={requestsPanel.changeOfDirectDebitLink.url}
        data-testid="overview-change-direct-debit-request-link"
        onClick={() =>
          logTealiumButtonClickEvent({ label: 'button/change-of-direct-debit' })
        }
      >
        {requestsPanel.changeOfDirectDebitLink.text}
      </FullWidthLink>
      <Hr marginTop="04" marginBottom="04" />
      <FullWidthLink
        to={requestsPanel.overpaymentsAllowanceLink.url}
        data-testid="overview-overpayments-allowance-request-link"
        onClick={() =>
          logTealiumButtonClickEvent({
            label: 'button/overpayments-allowance-link',
          })
        }
      >
        {requestsPanel.overpaymentsAllowanceLink.text}
      </FullWidthLink>
      <Hr marginTop="04" marginBottom="04" />
      <FullWidthLink
        to={requestsPanel.redemptionStatementLink.url}
        data-testid="overview-redemption-statement-request-link"
        onClick={() =>
          logTealiumButtonClickEvent({
            label: 'button/redemption-statement-link',
          })
        }
      >
        {requestsPanel.redemptionStatementLink.text}
      </FullWidthLink>
      <Hr marginTop="04" marginBottom="04" />
      <FullWidthLink
        to={requestsPanel.duplicateStatementLink.url}
        data-testid="overview-duplicate-statement-request-link"
        onClick={() =>
          logTealiumButtonClickEvent({
            label: 'button/duplicate-statement-link',
          })
        }
      >
        {requestsPanel.duplicateStatementLink.text}
      </FullWidthLink>
    </Box>
  );
}

export default RequestsPanel;
