import React, { useEffect } from 'react';

import {
  Button,
  ButtonPattern,
  Grid,
  GridItem,
  Main,
  Notification,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useNavigate } from '@interstellar/react-app-routing';
import * as routes from 'client/routes/manifest';
import {
  logTealiumButtonClickEvent,
  logTealiumPageViewEvent,
} from 'client/tealium';

import { RedemptionErrorContent } from './RedemptionErrorPage.config';

function RedemptionErrorPage() {
  const content = useContent<RedemptionErrorContent>();
  const navigate = useNavigate();

  useEffect(() => {
    logTealiumPageViewEvent({
      stepNumber: undefined,
      stepName: 'Redemption - Technical Error',
      action: 'Error',
      actionNarrative:
        "Sorry, we can't show your amount owing figure right now.",
      applicationState: 'Declined',
    });
  }, []);

  const {
    notification: { heading, text },
    returnLinkLabel,
  } = content;

  return (
    <Main>
      <Grid>
        <GridItem xs={12} lg={8}>
          <Notification
            sentiment="warning"
            heading={heading}
            data-testid="redemption-error-notification"
          >
            {text}
          </Notification>
          <ButtonPattern>
            <Button
              iconPosition="left"
              onClick={() => {
                logTealiumButtonClickEvent({
                  label: 'button/return-to-mortgage-overview',
                });
                navigate(routes.MortgageOverview);
              }}
              data-testid="return-to-overview-button"
            >
              {returnLinkLabel}
            </Button>
          </ButtonPattern>
        </GridItem>
      </Grid>
    </Main>
  );
}

export default RedemptionErrorPage;
