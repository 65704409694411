import React from 'react';

import { Box, Paragraph, Hr, Heading } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { FullWidthLink } from 'client/components/fullWidthLink';
import { logTealiumButtonClickEvent } from 'client/tealium';

import { MortgageOverviewPageContent } from '../../MortgageOverviewPage.config';

function OverpaymentsPanel() {
  const { overpaymentsPanel } = useContent<MortgageOverviewPageContent>();

  const {
    title,
    supportiveTextPara1,
  } = overpaymentsPanel;

  return (
    <Box bgColor="neutral2" data-testid="overview-overpayments-panel">
      <Heading as="h2" size="s3" marginBottom="02">
        {title}
      </Heading>
      <Paragraph>{supportiveTextPara1}</Paragraph>
      <Hr marginTop="05" marginBottom="05" />
      <FullWidthLink
        to={overpaymentsPanel.overpaymentLink.url}
        data-testid="overview-overpayments-link"
        onClick={() =>
          logTealiumButtonClickEvent({ label: 'button/overpaymentallowance' })
        }
      >
        {overpaymentsPanel.overpaymentLink.text}
      </FullWidthLink>
    </Box>
  );
}

export default OverpaymentsPanel;
