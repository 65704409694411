import { BaseQueryFn, createApi } from '@reduxjs/toolkit/query/react';
import { AxiosRequestConfig, AxiosError } from 'axios';

import axiosInstance from './axiosInstance';
import { FormParameters, FormResponse } from './types/form';
import { MortgageCountResponse } from './types/mortgageCount';
import { OverviewParameters, Overview } from './types/overview';
import { Redemption, RedemptionParameters } from './types/redemption';
import { ServiceResponse } from './types/serviceAvailability';

const axiosBaseQuery =
  (): BaseQueryFn<
    {
      url: string;
      method?: AxiosRequestConfig['method'];
      data?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
      headers?: AxiosRequestConfig['headers'];
    },
    unknown,
    unknown
  > =>
    async ({ url, method, data, params, headers }) => {
      try {
        // Use a custom axios instance so we can intercept responses.
        const result = await axiosInstance({
          url,
          method,
          data,
          params,
          headers,
        });
        return { data: result.data };
      } catch (axiosError) {
        const err = axiosError as AxiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    };

const api = createApi({
  reducerPath: 'api',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Auth', 'MortgageCount'],
  endpoints: (builder) => ({
    overview: builder.mutation<Overview, OverviewParameters | void>({
      query: (args) => {
        if (args) {
          const { accountNumber, dateOfBirth, postcode } = args;
          return {
            url: '/overview',
            method: 'post',
            data: { accountNumber, dateOfBirth, postcode },
          };
        }

        return {
          url: '/overview',
          method: 'post',
        };
      },
    }),
    redemption: builder.mutation<Redemption, RedemptionParameters>({
      query: ({ redemptionDate, requestType }) => ({
        url: '/redemption',
        method: 'post',
        data: { redemptionDate, requestType },
      }),
    }),
    signOut: builder.mutation<void, void>({
      query: () => ({
        url: '/sign-out',
        method: 'post',
      }),
      invalidatesTags: ['MortgageCount']
    }),
    form: builder.mutation<FormResponse, FormParameters>({
      query: (requestForm) => ({
        url: '/form',
        method: 'post',
        data: { requestForm },
      }),
    }),
    serviceAvailability: builder.query<ServiceResponse, void>({
      query: () => ({
        keepUnusedDataFor: 5,
        url: '/applicationAvailability',
        method: 'get',
      }),
    }),
    mortgageCount: builder.query<MortgageCountResponse, void>({
      query: () => ({
        url: '/mortgageCount',
        method: 'get',
      }),
      providesTags: ['MortgageCount']
    }),
  }),
});

export const {
  useOverviewMutation,
  useFormMutation,
  useSignOutMutation,
  useRedemptionMutation,
  useServiceAvailabilityQuery,
  useMortgageCountQuery,
} = api;

export default api;
