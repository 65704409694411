import React, { useEffect } from 'react';

import {
  ContentGroup,
  Heading,
  Hr,
  IconChevronLeft,
  Link,
  List,
  ListItem,
  Main,
  Paragraph,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useNavigate } from '@interstellar/react-app-routing';
import {
  logTealiumButtonClickEvent,
  logTealiumPageViewEvent,
} from 'client/tealium';

import { ProductTransferContent } from './ProductTransferPage.config';
import { MortgageOverview } from '../manifest';

function ProductTransferPage() {
  useEffect(() => {
    logTealiumPageViewEvent({
      stepNumber: '3',
      stepName: 'PT (Refer to broker)',
      applicationState: 'Referred',
    });
  }, []);

  const {
    backButtonLabel,
    title,
    titleIntro,
    brokerLink,
    brokerTitle,
    contactUsTitle,
    contactUsParagraph1,
    contactUsNumber2,
    contactUsAnythingElse,
    contactUsAnythingElseNumber,
    contactUsNumber1,
    contactUsParagraph2,
    wereHereTitle,
    contactMondayToFriday,
    contactMondayToFridayTime,
    contactSaturday,
    contactSaturday1,
    contactSaturday2,
    contactSaturday3,
    linesOpeningTime,
  } = useContent<ProductTransferContent>();
  const navigate = useNavigate();

  return (
    <Main>
      <Link
        as="button"
        icon={<IconChevronLeft trim />}
        iconPosition="left"
        onClick={() => {
          logTealiumButtonClickEvent({ label: 'button/back' });
          navigate(MortgageOverview);
        }}
        data-testid="refer-back-button"
      >
        {backButtonLabel}
      </Link>

      <ContentGroup marginBottom="none">
        <Heading as="h1" size="s7" marginTop="05" marginBottom="07">
          {title}
        </Heading>

        <Heading as="h2" size="s3">
          {titleIntro}
        </Heading>

        <Heading as="h2" marginBottom="01" size="s3">
          {brokerTitle}
        </Heading>
        <Paragraph>
          <Link
            external
            href={brokerLink.url}
            data-testid="refer-find-broker-link"
            onClick={() => {
              logTealiumButtonClickEvent({ label: 'button/searchforonehere' });
            }}
          >
            {brokerLink.text}
          </Link>
        </Paragraph>

        <Hr />

        <Heading as="h2" marginBottom="06" size="s3">
          {contactUsTitle}
        </Heading>
        <List marginBottom="06">
          <ListItem>
            {contactUsParagraph1}{' '}
            <Link href={`tel:${contactUsNumber1.phoneNumber}`}>
              {contactUsNumber1.phoneNumber}
            </Link>
            .
          </ListItem>
          <ListItem>
            {contactUsParagraph2}{' '}
            <Link href={`tel:${contactUsNumber2.phoneNumber}`}>
              {contactUsNumber2.phoneNumber}
            </Link>
            .
          </ListItem>
        </List>

        <Paragraph>
          {contactUsAnythingElse}{' '}
          <Link href={`tel:${contactUsAnythingElseNumber.phoneNumber}`}>
            {contactUsAnythingElseNumber.phoneNumber}
          </Link>
          .
        </Paragraph>

        <Heading as="h2" size="s3">
          {wereHereTitle}
        </Heading>

        <Paragraph marginBottom="none">{contactMondayToFriday}</Paragraph>
        <Paragraph>{contactMondayToFridayTime}</Paragraph>

        <Paragraph marginBottom="none">{contactSaturday}</Paragraph>
        <Paragraph marginBottom="none">
          <Link href={`tel:${contactSaturday1.number}`}>
            {contactSaturday1.number}{' '}
          </Link>
          {contactSaturday1.time}
        </Paragraph>

        <Paragraph marginBottom="none">
          <Link href={`tel:${contactSaturday2.number}`}>
            {contactSaturday2.number}{' '}
          </Link>
          {contactSaturday2.time}
        </Paragraph>

        <Paragraph>
          <Link href={`tel:${contactSaturday3.number}`}>
            {contactSaturday3.number}{' '}
          </Link>
          {contactSaturday3.time}
        </Paragraph>

        <Paragraph marginBottom="none">{linesOpeningTime}</Paragraph>
      </ContentGroup>
    </Main>
  );
}

export default ProductTransferPage;
