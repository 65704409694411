import getSafeLBGAnalytics from '../utils/getSafeLBGAnalytics';
import isTealiumAvailable from '../utils/isTealiumAvailable';

interface TealiumBlurEvent {
  fieldName: string;
  dwell?: number;
  error?: string;
}

/**
 * Logs a field update event, usually on the blur of the field, without capturing any data about the value of the field. If you want to capture the value of the input you should use logTealiumFieldUpdateEvent instead.
 * @param tealiumBlurEvent - the analytics data to send as part of the blur event
 * @param tealiumBlurEvent.fieldName The name of the field - usually the value of `e.target.name`
 * @param tealiumBlurEvent.dwell - Optional - Time in milliseconds the user has been inside of the field
 * @param tealiumBlurEvent.error - Optional - The validation error that the user can see when the field was blurred (if there is one).
 */
export function logTealiumBlurEvent({
  fieldName,
  dwell = 0,
  error = undefined,
}: TealiumBlurEvent) {
  if (isTealiumAvailable()) {
    getSafeLBGAnalytics().events.fieldUpdate(fieldName, dwell, error).send();
  }
}
