import React, { useEffect } from 'react';

import {
  Button,
  ButtonPattern,
  Grid,
  GridItem,
  Heading,
  Main,
  Paragraph,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useNavigate } from '@interstellar/react-app-routing';
import * as routes from 'client/routes/manifest';
import {
  logTealiumButtonClickEvent,
  logTealiumPageViewEvent,
} from 'client/tealium';

import { RedemptionSuccessContent } from './RedemptionSuccessPage.config';

function RedemptionSuccessPage() {
  const { heading, paragraph1, paragraph2, returnToOverviewLink } =
    useContent<RedemptionSuccessContent>();
  const navigate = useNavigate();

  useEffect(() => {
    logTealiumPageViewEvent({
      stepNumber: '2',
      stepName: "Redemption - We've got your request",
      applicationState: 'Fulfilled',
    });
  }, []);

  return (
    <Main>
      <Grid>
        <GridItem xs={12} lg={8}>
          <Heading as="h1" size="s5" data-testid="redemption-success-heading">
            {heading}
          </Heading>

          <Paragraph data-testid="redemption-success-paragraph-1">
            {paragraph1}
          </Paragraph>
          <Paragraph data-testid="redemption-success-paragraph-2">
            {paragraph2}
          </Paragraph>

          <ButtonPattern>
            <Button
              iconPosition="left"
              onClick={() => {
                logTealiumButtonClickEvent({
                  label: 'button/return-to-mortgage-overview',
                });
                navigate(routes.MortgageOverview);
              }}
              data-testid="return-to-overview-button"
            >
              {returnToOverviewLink}
            </Button>
          </ButtonPattern>
        </GridItem>
      </Grid>
    </Main>
  );
}

export default RedemptionSuccessPage;
