import getSafeLBGAnalytics from '../utils/getSafeLBGAnalytics';
import isTealiumAvailable from '../utils/isTealiumAvailable';

interface TealiumAccordionEvent {
  label: string;
}

/**
 * Logs an accordion event. This should be used only for the opening of the accordion, we should not track an accordion event for closing an accordion.
 *
 * @param tealiumAccordionEvent - the object containing analytics properties relating to the accordion.
 * @param tealiumAccordionEvent.label The title/label of the accordion that is being opened by the user
 */
export function logTealiumAccordionEvent({ label }: TealiumAccordionEvent) {
  if (isTealiumAvailable()) {
    getSafeLBGAnalytics().events.accordion('Supporting Material', label).send();
  }
}
