import React, { useEffect } from 'react';

import {
  ContentGroup,
  Heading,
  Link,
  Text,
  IconChevronLeft,
  Paragraph,
  Hr,
  Strong,
  Main,
  List,
  ListItem,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useNavigate } from '@interstellar/react-app-routing';
import {
  logTealiumButtonClickEvent,
  logTealiumPageViewEvent,
} from 'client/tealium';
import styled from 'styled-components';

import { OverpaymentsPageContent } from './OverpaymentsPage.config';
import * as routes from '../manifest';

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing_05};
`;

export default function OverpaymentsPage() {
  useEffect(() => {
    logTealiumPageViewEvent({
      stepNumber: '1',
      stepName: 'Overpayments',
      applicationState: 'Pre-Application',
    });
  }, []);

  const { title, bankTransfer, phone, backButtonLabel } =
    useContent<OverpaymentsPageContent>();

  const navigate = useNavigate();

  return (
    <Main>
      <StyledContainer>
        <Link
          icon={<IconChevronLeft trim />}
          iconPosition="left"
          as="button"
          data-testid="back-button-link"
          onClick={() => {
            logTealiumButtonClickEvent({ label: 'button/back' });
            navigate(routes.OverpaymentAllowance);
          }}
        >
          {backButtonLabel}
        </Link>
      </StyledContainer>

      <Heading as="h1" size="s7">
        {title}
      </Heading>

      <ContentGroup marginBottom="none">
        <Heading as="h2" size="s3">
          {bankTransfer.title}
        </Heading>

        <Paragraph>{bankTransfer.para1}</Paragraph>
        <Paragraph>{bankTransfer.para2}</Paragraph>
        <Paragraph>{bankTransfer.para3}</Paragraph>

        <Heading as="h2" size="s2" marginBottom="none">
          {bankTransfer.bankTitle}
        </Heading>

        <Text as="p" size="s2">
          {bankTransfer.bankSortCode}
          <Strong>{bankTransfer.bankSortCodeValue}</Strong>
        </Text>

        <Text as="p" size="s2">
          {bankTransfer.bankAccNo}
          <Strong>{bankTransfer.bankAccNoValue}</Strong>
        </Text>

        <Text as="p" size="s2">
          {bankTransfer.bankName}
          <Strong>{bankTransfer.bankNameValue}</Strong>
        </Text>

        <Text as="p" size="s2" marginBottom="05">
          {bankTransfer.bankRef}
          <Strong>{bankTransfer.bankRefValue}</Strong>
        </Text>

        <Paragraph>{bankTransfer.para4}</Paragraph>
      </ContentGroup>

      <Hr />

      <ContentGroup marginBottom="none">
        <Heading as="h2" size="s3">
          {phone.title}
        </Heading>

        <Paragraph>
          {phone.para1A}
          <Strong>{phone.para1B}</Strong>
          {phone.para1C}
          <Strong>{phone.para1D}</Strong>
          {phone.para1E}
        </Paragraph>
        <List>
          <ListItem>{phone.listItems[0]}</ListItem>
          <ListItem>{phone.listItems[1]}</ListItem>
          <ListItem>{phone.listItems[2]}</ListItem>
        </List>
        <Paragraph>{phone.para2}</Paragraph>
      </ContentGroup>

      <StyledContainer>
        <Link
          icon={<IconChevronLeft trim />}
          iconPosition="left"
          as="button"
          data-testid="bottom-back-button-link"
          onClick={() => {
            logTealiumButtonClickEvent({ label: 'button/back' });
            navigate(routes.OverpaymentAllowance);
          }}
        >
          {backButtonLabel}
        </Link>
      </StyledContainer>
    </Main>
  );
}
